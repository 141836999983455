/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
// regular style toast
@import '~ngx-toastr/toastr';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

app-root,
app-sign-in,
app-seat-buy,
app-sign-up,
app-registration-form,
app-dashboard,
app-pick-role-type,
app-role-type,
app-welcome-page,
app-teacher-class,
app-teacher-classes-list,
app-csv-import-button,
app-board-header,
app-loading,
app-student-progress,
app-student-quizzes,
app-tabs,
app-download-csv,
app-license-expire-date,
app-admin-board {
  display: contents;
}

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.ag-root-wrapper.ag-layout-normal.ag-ltr {
  flex-grow: 1;
}

button,
.btn {
  transition: 0.1s ease-in-out;

  &:disabled {
    opacity: 0.5;
  }

  &:hover:not([disabled]) {
    opacity: 0.8;
    transition: 0.1s ease-in-out;
  }
}

.load {
  position: absolute;
  background: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: inherit;
}

.load::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  width: 30px;
  height: 30px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 1s ease infinite;
  z-index: 10;
}

.load::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 3px dashed #fff;
  width: 30px;
  height: 30px;
  border-left: 3px solid transparent;
  border-bottom: 3px solid transparent;
  animation: loading1 2s linear infinite;
  z-index: 5;
}

@keyframes loading1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.disabled-grid-angular {
  opacity: 0.6;
  pointer-events: none;

  .loading {
    display: none;
  }
}

.btn-secondary {
  background-color: #0071bc;
  border-color: #46d6e2;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  width: 300px;
  height: 48px;
  padding: 0 20px;
  outline: none;
  box-shadow: 0 2px 2px #00000040;
  border-radius: 24px;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;
  transition: all 0.3s;
  @media screen and (max-width: 1000px) {
    .promo-block-alt-btn {
      width: 200px;
      height: 32px;
      padding: 0 20px;
      font-size: 16px;
    }
  }

  &:hover {
    background-color: #fff;
    color: #0071bc;
  }
}

.h-full-calc {
  height: calc(100vh - 90px);
}

._failed {
  border-bottom: solid 4px red !important;
}
._failed i {
  color: red !important;
}

._success {
  box-shadow: 0 15px 25px #00000019;
  padding: 45px;
  width: 100%;
  text-align: center;
  margin: 40px auto;
  border-bottom: solid 4px #28a745;
}

._success i {
  font-size: 55px;
  color: #28a745;
}

._success h2 {
  margin-bottom: 12px;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 10px;
}

._success p {
  margin-bottom: 0;
  font-size: 18px;
  color: #495057;
  font-weight: 500;
}
